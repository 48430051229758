import 'src/common/instrumentation';

import { setupEnv, setupLocalEnv } from 'src/setupEnv';

const IS_LOCAL_ENVIRONMENT = process.env.NODE_ENV === 'development';
const init = async () => {
  if (IS_LOCAL_ENVIRONMENT) {
    await setupLocalEnv();
  } else {
    await setupEnv();
  }
  import('./bootstrap');
};

init();

export {};
