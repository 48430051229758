import { initEntryPoint, setParameter, getParameter } from '@adjust/mfe-tools';

declare global {
  interface Window {
    MFE_REMOTE_CONFIG?: Record<string, string>;
  }
}

type IConfig = Record<string, string>;

export const setParameters = () => {
  setParameter('MFE_NODE_ENV', process.env.NODE_ENV);
  setParameter('API_DOMAIN', process.env.REACT_APP_API_DOMAIN);
  setParameter(
    'DASHBOARD_API_DOMAIN',
    process.env.REACT_APP_DASHBOARD_API_DOMAIN
  );
  setParameter('RELEASE_ENV', process.env.REACT_APP_RELEASE_ENV);
};

export const setLocalParameters = () => {
  setParameter('API_URL', 'https://local.adjust.com:3003');
  setParameters();

  if (!getParameter('DASHBOARD_API_DOMAIN')) {
    const domainEnv = process.env.REACT_APP_DOMAIN_ENV;
    setParameter(
      'DASHBOARD_API_DOMAIN',
      `https://dash-${domainEnv}.adjust.com`
    );
  }
};

const fetchConfigForLocalEnvironment = (): Promise<IConfig> => {
  // TODO fetch a real configuration
  return Promise.resolve({});
};

export const setupLocalEnv = async () => {
  const domainEnv = process.env.REACT_APP_DOMAIN_ENV;

  initEntryPoint(`dash-${domainEnv}.adjust.com`, {
    invalidateCache: true,
    remoteUrlPath: process.env.REACT_APP_REMOTE_URL_PATH,
    config: await fetchConfigForLocalEnvironment(),
  });

  setLocalParameters();
};

export const setupEnv = async () => {
  initEntryPoint(window.location.hostname, {
    invalidateCache: true,
    remoteUrlPath: process.env.REACT_APP_REMOTE_URL_PATH,
    config: window.MFE_REMOTE_CONFIG || {},
  });
  setParameters();
};
