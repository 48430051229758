const instrumentedRequests = [
  'reports-service/report',
  'configs-service/suite_config',
  'configs-service/filters?source=dashboard',
  'accounts/current_account',
];

const requestsObserverCallback = (list: PerformanceObserverEntryList) => {
  list.getEntries().forEach(entry => {
    const matchedRequest = instrumentedRequests.find(req =>
      entry.name.includes(req)
    );
    if (matchedRequest) {
      window.newrelic?.addPageAction(`be-${matchedRequest}`, {
        duration: Math.round(entry.duration),
      });
    }
  });
};

export const requestsObserver = new PerformanceObserver(
  requestsObserverCallback
);
requestsObserver.observe({
  type: 'resource',
});
